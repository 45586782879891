.kontakt-page .main-image::before {
  content: "";
  background-image: url("../../images/Kontakt/KontaktMain.jpg");
  opacity: 0.9;
}/*# sourceMappingURL=KontaktPage.css.map */

.kontakt-page .dzial-container {
  width: 80%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: auto;
  #margin-top: 20px;
}
.kontakt-page .dzial-container .header-container h1 {
  position: relative;
  text-align: right;
  margin-top: 30px;
  font-size: 50px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
}
.kontakt-page .dzial-container .header-container h1::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 5px;
  bottom: -5px;
  left: 0;
  background-color: #e31922;
}
.kontakt-page .dzial-container .no-leader h1::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 5px;
  top: 0px;
  left: 0;
  background-color: #e31922;
}
.kontakt-page .kontakt-maile {
	text-align: center;
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	flex-basis: 30%;
	justify-content: space-evenly;
}
.kontakt-page .kontakt-maile svg {
	color: red;
}
.kontakt-page .dzial-container .leader {
  margin-bottom: 25px;
}
.kontakt-page .dzial-container .leader, .kontakt-page .dzial-container .person {
  flex-basis: 24%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.kontakt-page .dzial-container .leader img, .kontakt-page .dzial-container .person img {
  width: 230px;
  height: 345px;
  -o-object-fit: contain;
     object-fit: contain;
  background-color: #BF4046;
  border-radius: 30px;
}
.kontakt-page .dzial-container .leader h2, .kontakt-page .dzial-container .leader h3, .kontakt-page .dzial-container .person h2, .kontakt-page .dzial-container .person h3 {
  text-align: center;
  height: 30px;
}
.kontakt-page .dzial-container .leader h3, .kontakt-page .dzial-container .person h3 {
  height: 80px;
  color: #1E212B;
  font-style: italic;
}
.kontakt-page .dzial-container .description {
  margin-bottom: 50px;
}
.kontakt-page .dzial-container .description p {
  font-size: 20px;
}
.kontakt-page .dzial-container .persons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}
.kontakt-page .dontshow {
  display: none;
}

@media (max-width: 620px) {
  .kontakt-page .dzial-container .header-container h1 {
    font-size: 50px;
  }
}
@media (max-width: 440px) {
  .kontakt-page .dzial-container .header-container h1 {
    font-size: 35px;
  }
}
svg {
  vertical-align: middle;
  transform: scale(1.2);
}

.kontakt-page .map-header-container h1{
	position: relative;
  text-align: right;
  margin-top: 30px;
  font-size: 50px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
}

.kontakt-page .map-header-container h1::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 5px;
  bottom: -5px;
  left: 0;
  background-color: #e31922;
}
.kontakt-page .map-header-container h1::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 5px;
  top: 0px;
  left: 0;
  background-color: #e31922;
}
.kontakt-page iframe{
	height: 450px;
	width: 80%;
	margin: auto;
	border: none;
}

.kontakt-page .map-container{
	width: 80%;
	#height: 1000px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: auto;
  margin-top: 20px;
}
