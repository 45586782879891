.partners-main-page {
  width: 1330px;
  max-width: 90%;
  color: #1E212B;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.partners-main-page .partners-headline {
  position: relative;
  font-size: 80px;
  margin-bottom: 20px;
}
.partners-main-page .partners-headline::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  width: 100%;
  background-color: #1E212B;
}
.partners-main-page .strategiczni {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.partners-main-page .strategiczni {
  #width: 930px;
  max-width: 100%;
}
.partners-main-page .strategiczni a {
  position: relative;
  width: 50%;
  display: flex;
  align-items: center;
}
.partners-main-page .strategiczni a img {
  width: 95%;
  -o-object-fit: contain;
     object-fit: contain;
}
.partners-main-page .glowni, .partners-main-page .zaufali {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
}
.partners-main-page .glowni a, .partners-main-page .zaufali a {
  flex-basis: calc(11% - 20px);
  margin: 10px;
  display: flex;
  align-items: center;
}
.partners-main-page .glowni a img, .partners-main-page .zaufali a img {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.partners-main-page .zaufali a {
  flex-basis: calc(9% - 20px);
}
.partners-main-page .glowni a img, .partners-main-page .zaufali a img, .partners-main-page .strategiczni a img {
  transition: all 0.2s;
}
.partners-main-page .glowni a:hover img, .partners-main-page .zaufali a:hover img, .partners-main-page .strategiczni a:hover img {
  scale: 1.1;
}

@media (max-width: 820px) {
  .partners-main-page .partners-headline {
    font-size: 45px;
  }
  .partners-main-page .zaufali {
    #display: none;
  }
  .partners-main-page .glowni a {
    flex-basis: calc(16.6% - 20px);
  }
}
@media (max-width: 620px) {
  .partners-main-page .partners-headline {
    font-size: 45px;
  }
  .partners-main-page .glowni a img, .partners-main-page .zaufali a img {
  width: 120%;
  }
}/*# sourceMappingURL=PartnersMain.css.map */