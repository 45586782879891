@import "../variables";

.bolid-page {
    width: 100vw;

    .main-image {
        &::before {
            background-image: url("../../images/Bolid/BolidMain.jpeg");
            opacity: 0.8;
        }
    }


    .bolids-animation {
        margin-top: 200px;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .circle {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            background-color: $REDCOLOR;
        }

        .firstLine {
            overflow-y: hidden;

            position: relative;
            width:10px;
            height: 300px;

            .main-line {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 0px;
                background: $REDCOLOR;
                z-index: 5;
            }

            .gray-background {
                position: absolute;
                top: 0px;
                left: 2px;
                width: 6px;
                height: 300px;
                background: gray;
                z-index: 2;
            }
            
        }

        .WUT3-bolid {
            height: 60vh;
            width: 80vw;
            // background-color: $REDSHADOWCOLOR;
            // background-color: black;
            border-radius: 3px;
            display: flex;
            flex-direction: column;

            .bolid-name {
                text-align: center;
                color: white;
                font-size: 50px;
                color: black;
            }

            .container {
                display: flex;
                flex-direction: row;

                .image-bolid {
                    object-fit: contain;
                    height: 80%;
                    width: 100%;
                    opacity: 1;
                }

                // .bolid-stats {
                //     width: 50%;
                //     height: 20%;
                //     background-color: black;
                // }

            }


        }
    }
}