html {
	scroll-behavior: smooth;
}
.team-page {
  width: 100vw;
}
.team-page .btnTop {
	position: fixed;
	right: 20px;
	bottom: 20px;
	background-color: #e31922;
	border: 3px solid #e31922;
	border-radius: 3px;
	padding: 8px;
}
.team-page .btnTop a {
	display: block;
	width: 15px;
	height: 15px;
}
.team-page .btnTop img {
	width: 15px;
	height: 15px;
}
.team-page .form-pick-dzial {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
.team-page .form-pick-dzial button {
  width: auto;
  padding: 0px 20px 0px 20px;
  height: 50px;
  border: 5px solid #e31922;
  color: #e31922;
  margin: 10px;
}
.team-page .form-pick-dzial button:hover {
  font-size: 20px;
  background-color: #e31922;
  color: #FFFFFA;
}
.team-page .form-pick-dzial .active {
  background-color: #e31922;
  color: #FFFFFA;
}
.team-page .main-image {
  position: relative;
  height: 70vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.team-page .main-image::before {
  content: "";
  background-image: url("../../images/Team/MainTeam.jpeg");
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.8;
}
.team-page .main-image h1 {
  position: relative;
  bottom: -100px;
  color: #1E212B;
  font-size: 120px;
  line-height: 0.9;
  text-align: center;
}
.team-page .dzial-container {
  width: 80%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: auto;
  #margin-top: 20px;
}
.team-page .dzial-container .header-container h1 {
  position: relative;
  text-align: right;
  margin-top: 30px;
  font-size: 60px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
}
.team-page .dzial-container .header-container h1::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 5px;
  bottom: -5px;
  left: 0;
  background-color: #e31922;
}
.team-page .dzial-container .no-leader h1::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 5px;
  top: 0px;
  left: 0;
  background-color: #e31922;
}
.team-page .dzial-container .leader {
  #margin-bottom: 25px;
}
.team-page .dzial-container .leader, .team-page .dzial-container .person {
  flex-basis: 21%;
  display: flex;
  #justify-content: center;
  align-items: center;
  flex-direction: column;
}
.team-page .dzial-container .leader img, .team-page .dzial-container .person img {
  width: 230px;
  height: 345px;
  -o-object-fit: contain;
     object-fit: contain;
  background-color: #BF4046;
  border-radius: 30px;
}
.team-page .dzial-container .leader h2, .team-page .dzial-container .leader h3, .team-page .dzial-container .person h2, .team-page .dzial-container .person h3 {
  text-align: center;
  #height: 30px;
}
.team-page .dzial-container .leader h3, .team-page .dzial-container .person h3 {
  height: 50px;
  color: #1E212B;
  font-style: italic;
}
.team-page .dzial-container .description {
  margin-bottom: 50px;
}
.team-page .dzial-container .description p {
  font-size: 20px;
  text-align: center;
}
.team-page .dzial-container .persons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}
.team-page .dontshow {
  display: none;
}

@media (max-width: 620px) {
  .team-page .dzial-container .leader img, .team-page .dzial-container .person img {
	  width: 195px;
	  height: auto;
	}
  .team-page .dzial-container {
	  width: 90%;
  }
  .team-page .main-image h1 {
	  left: 0px;
	  font-size: 90px;
  }
  .team-page .dzial-container .description p {
	  font-size: 18px;
  }
  .team-page .form-pick-dzial button {
	  font-size: 16px;
	  border: 3px solid #e31922;
  }
}
@media (max-width: 440px) {
  .team-page .dzial-container .header-container h1 {
    font-size: 35px;
  }
  .team-page .dzial-container .leader img, .team-page .dzial-container .person img {
	  width: 160px;
	  height: auto;
	}
  .team-page .dzial-container {
	  width: 95%;
  }
}/*# sourceMappingURL=TeamPage.css.map */