@import "./variables";

.navbar {
  position: fixed;
  left: 0; 
  right: 0;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 23px;
  z-index: 30;
  background-color: #000;
  transition: all 0.3s ease;
  height: 120px;

  transform: translateY(-200px);
  animation-name: movenavbar;
  animation-duration: 1.5s;
  animation-delay: $timeshow;
  animation-fill-mode: forwards;

  @keyframes movenavbar {
    from {transform: translateY(-200px);}
    to {transform: translateY(0px);}
  }

  .navbar-logo {
    transform: translateY(12px);
    &:hover {
        cursor: pointer;
    }

    img {
        width: 400px;
      
    }


  }

  .navbar-subsites {
      display: flex;
      flex-direction: row;
        // align-items: center;
        // justify-content: center;
        margin-top: auto;
        margin-bottom: auto;


      .subsite-text-container {
          margin: auto 2px auto 2px;
          padding: 10px 40px 10px 40px;
          position: relative;

          display: flex;
          flex-direction: row;
          //background-color: $REDCOLOR;
          color: #fff;
          //font-family: 'Maven Pro', sans-serif;
          transition: all 0.2s;

          &::before {
            content: ""; 
            position: absolute;
            width: 100%;
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: $REDCOLOR;
            visibility: hidden;
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transition: all 0.2s ease-in-out 0s; 
            transition: all 0.2s ease-in-out 0s; 

        }  
    

          &:hover {
              cursor: pointer;
              color: $REDCOLOR;

              &::before {
                visibility: visible;
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
            }   
          }

          .subsites-text {
              font-size: 1.2em;
              font-weight: 600;
              margin: auto;
              text-align: center;
          }

          h3 {
              color: rgba(255, 255, 255, 0.76); 
              font-size: 0.94em;
              font-weight: 200;
              margin: auto 40px auto 40px;
              text-align: center;
              opacity: 0%;
              transition: all 0.5s ease;
              cursor: default;
          }

          .text-hovered {
              opacity: 100%;
          }
      }   

      .clicked {
        }
        .clicked::before {
            visibility: visible;
            transform: scaleX(1);
        }

    
      .countries {
        // transform: translateY(-15px);
        display: flex;
        flex-direction: column;


        &:hover {
            cursor: unset;
            &::before {
                visibility: hidden;
            }
        }

        img {
            position: relative;
            margin: 5px;
            &:hover {
                cursor: pointer;
            }
        }
        
        picture.chosen-language {
            position: relative;

            &::before {
                content: "";
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                background-color: green;
            }
        }

        .chosen-language {
            position: relative;
        }

        // &::before {
        //     transition: none;
        // }
      }
  }

}

@media screen and (max-width: $EXTRALARGESCREEN) {
    .navbar .navbar-subsites .subsite-text-container {
        padding: 10px 25px 10px 25px;
        
    }
}

@media screen and (max-width: $LARGESCREEN) {
    .navbar {
        .navbar-logo {
            &:hover {
                cursor: pointer;
            }
        
            img {
                width: 350px;
                //transform: translateX(20px);
                transform: translateY(4px);
            }
        }
        .navbar-subsites {
            .subsite-text-container {
                padding: 10px 13px 10px 13px;

                .subsites-text {
                    font-size: 1.1em;
                    font-weight: 600;
                    margin: auto;
                    text-align: center;
                }
            }


        }
    }
}


@media only screen and (max-width:1024px) {
  .navbar {
      .navbar-subsites {
          display: flex;
          flex-direction: row;

          .subsite-text-container {
              margin: auto 0px auto 0px;
          }
      }
  }
}




.page-navbar {
    position: fixed;
    top: 0px;
    // left: 0;
    width: 100%;
    background-color: black;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;

    & .page-navbar-holder {
        // position: fixed;
        // top: 0px;
        // background-color: black;
      width: 90%;
      margin: 0px auto 0px auto;
      display: flex;
      justify-content: space-between;
      z-index: 700;
    }
  
    & .navbar-logo {
        cursor: pointer;
      display: flex;
      align-items: center;
      margin-left: 0px;
  
      transition: margin-left 0.5s ease-in;
  
      img {
            width: 400px;
            transform: translateX(20px);
        }

      & .logo-text {
        font-size: 50px;
        margin: 0px;
        padding: 0px;
      }
    }
  }
  
  .navbar-menu {
    margin: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
  }
  
  .falling-nav {
    // display: none;
    top: 100px;
    position: fixed;
    overflow: hidden;
    width: 100%;
    // height: 250px;
    height: 0px;
    background-color: black;
    z-index: 800;
  
    transition: height 0.5s ease-in;
  
    & ul {
      list-style: none;
      margin: 0px auto 0px auto;
      width: 90%;
      list-style: none;
    }
  
    & li {
        cursor: pointer;
        font-size: 40px;
        background-color: black;
        transition: background-color 0.2s ease-in;
        
        a {
            text-decoration: none;
            color: white;
        }

        &:hover {
            background-color: rgba($REDCOLOR, 0.6);
        }
    }

    .border-bottom {
        border-bottom: 1px solid rgba(white, 0.8);
    }
  }
  
  .change .falling-nav {
    height: 250px;
  }
  
  .bar-container {
    display: inline-block;
    cursor: pointer;
  
    .bar1,
    .bar2,
    .bar3 {
      width: 35px;
      height: 5px;
      background-color: white;
      margin: 6px 0px;
      transition: 0.4s;
      border-radius: 10px;
    }
  }
  
  .change {
    & .bar1 {
      transform: rotate(-45deg) translate(-7px, 6px);
    }
    & .bar2 {
      opacity: 0;
    }
    & .bar3 {
      transform: rotate(45deg) translate(-9px, -8px);
    }
  }
  
  .unscrolled,
  .scrolled {
    & .navbar-menu-md {
      margin: 0px;
      padding: 0px;
      display: flex;
      align-items: center;
      li {
        height: 100%;
        font-size: 30px;
        list-style: none;
        margin-left: 40px;
        padding: 10px;
  
        transition: 0.2s;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }