.about-page {
  width: 100%;
  background-color: #FFFFFA;
  padding-bottom: 50px;
}
.about-page .main-image::before {
  content: "";
  background-image: url("../../images/About/AboutImg.jpg");
  opacity: 0.3;
}
.about-page .main-text-container {
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
}
.about-page .main-text-container .red-color {
  color: #e31922;
}
.about-page .main-text-container .opening-text {
  color: #1E212B;
  width: 100%;
}
.about-page .main-text-container .opening-text h2 {
  font-size: 30px;
}
.about-page .main-text-container .opening-text h2:nth-of-type(2) {
  padding-top: 50px;
  font-size: 35px;
}
.about-page .main-text-container .opening-text ul {
  list-style: none;
}
.about-page .main-text-container .opening-text ul li {
  list-style-position: inside;
  font-size: 25px;
  font-weight: 200;
}
.about-page .main-text-container .opening-text ul li::before {
  content: "- ";
  color: #e31922;
}
.about-page .main-text-container .img-container-about {
  margin-top: 100px;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.about-page .main-text-container .img-container-about img {
  margin: 10px;
  width: 70%;
}
.about-page .main-text-container .history-text {
  margin-top: 100px;
  width: 100%;
}
.about-page .main-text-container .history-text .history-text-container {
  display: flex;
}
.about-page .main-text-container .history-text .history-text-container h1 {
  position: relative;
  text-align: left;
  color: #1E212B;
  font-size: 70px;
}
.about-page .main-text-container .history-text h2 {
  padding-top: 20px;
  color: #1E212B;
  font-size: 25px;
  font-weight: 200;
}/*# sourceMappingURL=AboutPage.css.map */

@media screen and (max-width: 620px) {
	.about-page .main-text-container .history-text h2 {
		font-size: 1.3em;
	}
	.about-page .main-text-container .opening-text h2 {
		font-size: 25px;
	}
	.about-page .main-text-container .opening-text ul li {
		font-size: 22px;
	}
	.about-page .main-text-container .opening-text h2:nth-of-type(2) {
		font-size: 30px;
		text-align: left;
	}
}