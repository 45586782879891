.partners-page {
  width: 100%;
  margin-top: 120px;
}
.partners-page .headline-partners {
  position: relative;
  font-size: 64px;
  color: #1E212B;
  margin-top: 40px;
  text-align: center;
}
.partners-page .headline-partners::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 1s;
  height: 5px;
  width: 100%;
  background-color: #1E212B;
}
.partners-page .strategiczni {
  #margin: auto;
  width: 140%;
  max-width: 150%;
  margin-top: 20px;
}
.partners-page .strategiczni a {
  flex-basis: 50%;
  #margin: 0px 10px;
}
.partners-page .glowni a {
  flex-basis: calc(20% - 20px);
}
.partners-page .zaufali a {
  flex-basis: calc(16% - 20px);
}

.partners-container {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}/*# sourceMappingURL=PartnersPage.css.map */

.partners-page .PW_logo{
	width: 250px;
	max-width: 50%;
	padding-bottom: 30px;
	padding-top: 10px;
}

@media (max-width: 820px) {
  .partners-page .headline-partners {
    font-size: 45px;
  }
  .partners-container{
	  width: 100%;
  }
  .partners-page .strategiczni {
	  width: 100%;
  }
}