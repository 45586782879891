@import "../variables";

$MANAGECOLOR: #BF4046;

.manage-front-page {
    background-color: $MANAGECOLOR;
    width: 100%;
    height: 600px;
    display: flex;
    flex-direction: row;
    // align-items: center;

    .manage-container {
        display: flex;
        margin: auto;
        width: 90%;


        .manage-pictures {
            flex: 1;
            display: flex;
            
            align-items: center;
            flex-direction: row;
    
            .holder-bent {
                // width: 200px;
                // height: 100px;
                // position:relative;
                // z-index: 20;
                width: 267px;
                height: 400px;
                position: relative;
                transition: opacity 1s;
                opacity: 0%;
    
                img {
                    // position: relative;
                    
                    height: 100%;
                    object-fit: contain;
                    
                }
    
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 0;
                    height: 0;
                    border-top: 400px solid transparent;
                    border-left: 70px solid $MANAGECOLOR;
                }
    
                &::after {
                    content: "";
                    position: absolute;
                    transition: unset;
                    top: 0;
                    right: 0px;
                    width: 0;
                    height: 0;
                    border-top: 400px solid $MANAGECOLOR;
                    border-left: 70px solid transparent;
                    
                }
                //     // &:nth-of-type(3) {
                //     //     z-index: 
                //     // }
                // }
            }
        }
    
        .manage-text {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            align-self: center;
            color: white;
            // display: flex;
            // flex-direction: column;
            
            .text-headers {
                padding: 10px;
                // background-color:#D7262E;
                border: 8px solid white;
                h1 {
                    font-size: 70px;
                    font-weight: 600;
                    text-align: center;
                    
                    
                    
                    // background-color: linear-gradient(to right, #a438ab, rgba(164, 56, 171, 0.5));
                }
            }

            button {
                margin-top: 100px;
            }
        }
    }

    
}

@media screen and (max-width: $EXTRALARGESCREEN) {
    .manage-front-page {
        .manage-container {
            .manage-pictures { 
                .holder-bent {
                    width: 240px;
                    height: 360px;

                    // img {
                    //     height: 80%;
                    // }
                }
            }

            .manage-text {
                flex: 1;

                .text-headers { 
                    h1 {
                        font-size: 60px;   
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $LARGESCREEN) {
    .manage-front-page {
        height: 500px;

        .manage-container {
            .manage-pictures { 
                .holder-bent {
                    width: 213px;
                    height: 320px;

                }
            }

            .manage-text {
                flex: 1;

                .text-headers { 
                    h1 {
                        font-size: 50px;   
                    }
                }
            }
        }
    }
}


@media screen and (max-width: $LARGESCREEN) {
    .manage-front-page {
        height: 900px;
        .manage-container {
            flex-direction: column;

            .manage-pictures {
                margin-top: 30px;
                margin: auto;
                .holder-bent {
                    width: 280px;
                    height: 420px;
        
                    &::before {
                        border-top: 420px solid transparent;
                        border-left: 70px solid $MANAGECOLOR;
                    }
        
                    &::after {
                        border-top: 420px solid $MANAGECOLOR;
                        border-left: 70px solid transparent;
                        
                    }
                }
            }

            .manage-text {
                margin-top: 20px;
                flex: 1;

                .text-headers { 
                    h1 {
                        font-size: 70px;   
                    }
                    
                }

                button {
                    margin-top: 50px;
                }
            }
        }
    }
}

@media  screen and (max-width: $SMALLNORMALSCREEN) {
    .manage-front-page {
        height: 700px;

        .manage-container {
            .manage-pictures {
                .holder-bent {
                    width: 220px;
                    height: 330px;
        
                    &::before {
                        border-top: 330px solid transparent;
                        border-left: 70px solid $MANAGECOLOR;
                    }
        
                    &::after {
                        border-top: 330px solid $MANAGECOLOR;
                        border-left: 70px solid transparent;
                        
                    }
                }
            }

            .manage-text {
                margin-top: 20px;

                .text-headers { 
                    h1 {
                        font-size: 50px;   
                    }
                    
                }
            }
        }
    }
}

@media  screen and (max-width: $SMALLSCREEN) {
    .manage-front-page {
        height: 600px;

        .manage-container {
            .manage-pictures {
                .holder-bent {
                    width: 160px;
                    height: 240px;
        
                    &::before {
                        border-top: 240px solid transparent;
                        border-left: 40px solid $MANAGECOLOR;
                    }
        
                    &::after {
                        border-top: 240px solid $MANAGECOLOR;
                        border-left: 40px solid transparent;
                        
                    }
                }
            }

            .manage-text {
                .text-headers { 
                    h1 {
                        font-size: 40px;   
                    }
                    
                }
            }
        }
    }
}

@media screen and (max-width: $EXTRASMALLSCREEN) {
    .manage-front-page {
        height: 500px;

        .manage-container {
            .manage-pictures {
                .holder-bent {
                    width: 140px;
                    height: 210px;
        
                    &::before {
                        border-top: 210px solid transparent;
                        border-left: 30px solid $MANAGECOLOR;
                    }
        
                    &::after {
                        border-top: 210px solid $MANAGECOLOR;
                        border-left: 30px solid transparent;
                        
                    }
                }
            }

            .manage-text {
                .text-headers { 
                    h1 {
                        font-size: 35px;   
                    }
                    
                }

                button {
                    margin-top: 30px;
                }
            }
        }
    }
}

@media screen and (max-width: $EXTRAEXTRASMALLSCREEN) {
    .manage-front-page {
        height: 500px;

        .manage-container {
            .manage-pictures {
                .holder-bent {
                    width: 120px;
                    height: 180px;
        
                    &::before {
                        border-top: 180px solid transparent;
                        border-left: 30px solid $MANAGECOLOR;
                    }
        
                    &::after {
                        border-top: 180px solid $MANAGECOLOR;
                        border-left: 30px solid transparent;
                        
                    }
                }
            }
        }
    }
}