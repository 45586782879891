@import "../variables";

.partners-main-page {
  // padding-top: 100px;
  width: 70%;
  color: $FONTCOLORBLACK;
  margin: auto;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .partners-headline {
    position: relative;
    font-size: 80px;
    margin-bottom: 20px;

    &::after {
      content: ""; 
      position: absolute;
      bottom:0;
      left:0;
      height: 5px;
      width: 100%;
      background-color: $FONTCOLORBLACK;
    }
  }

  .partners-holder {
    
  }

  .strategiczni {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .strategiczni {
    width: 70%;
    a {
      position: relative;
      width: 25%;
      display: flex;
      align-items: center;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }

  .glowni, .zaufali{
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;

    a {
      // flex-basis: 11%;
      flex-basis: calc(11% - 20px);
      // flex-grow: 1;
      margin: 10px;

      display: flex;
      align-items: center;
      img {
       
        width: 100%;
        object-fit: contain;
        // height: 150px;
      }
    }
  }

  .zaufali {
    a {
      flex-basis: calc(9% - 20px);
    }
  }

  .glowni, .zaufali, .strategiczni {
    a {
      img {
        transition: all 0.2s;
      }
      &:hover {
        img {
          scale: 1.1
        }
      }
    }
  }
}

@media (max-width: $SMALLSCREEN) {
  .partners-main-page {
    .partners-headline {
      font-size: 55px;
    }

    .zaufali {
      display: none;
    }

    .glowni {
      a {
        flex-basis: calc(16.6% - 20px);
      }
    }
  }
}

@media (max-width: $EXTRASMALLSCREEN) {
  .partners-main-page {
    .partners-headline {
      font-size: 45px;
    }
  }
}