@import "../variables";

.team-page {
  width: 100vw;

  .form-pick-dzial {

    margin-top: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    button {
      width: auto;
      padding: 0px 20px 0px 20px;
      height: 50px;
      border: 5px solid $REDCOLOR ;
      color: $REDCOLOR;
      margin: 10px;

      &:hover {
        font-size: 20px;
        background-color: $REDCOLOR;
        color: $WHITECOLOR;
      }
    }

    .active {
      background-color: $REDCOLOR;
      color: $WHITECOLOR;
    }
  }

  .main-image {
    position: relative;
    height: 60vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {    
      content: "";
      background-image: url('../../images/Team/MainTeam.jpeg');
      background-size: cover;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 0.7;
    }

    h1 {
      position: relative;
      color: $FONTCOLORBLACK;  
      font-size: 120px;
      line-height: 0.9;
      text-align: center;
      // text-shadow: black;
      // text-shadow: 0 0 3px rgba(255,255,255,.5), 0 0 10px rgba(255,255,255,.5), 0px 0px 4px rgba(0,0,0,0.57);
    }
  }

  .dzial-container {
    width: 80%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: auto;
    margin-top: 20px;

    .header-container {
      h1 {
        position: relative;
        text-align: right;
        margin-top: 30px;
        // color: black;
        font-size: 70px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 30px;
  
        &::before {
          content: ""; 
          position: absolute;
          width: 100%;
          height: 5px;
          bottom: -5px;
          left: 0;
          background-color: $REDCOLOR;
        }   
      }
    }

    .no-leader {
      h1 {
        &::after {
          content: ""; 
          position: absolute;
          width: 100%;
          height: 5px;
          top: 0px;
          left: 0;
          background-color: $REDCOLOR;
        }   
      }
    }

    .leader {
      margin-bottom: 25px;
    }

    .leader, .person {
        flex-basis: 21%;

        // width: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        // border: 5px solid red;
  
        img {
          width: 230px;
          height: 345px;
          object-fit: contain;

          background-color: $REDSHADOWCOLOR;
        }

        h2, h3 {
          text-align: center;
          height: 30px;
        }
        
        h3 {
          height: 50px;
          color: $FONTCOLORBLACK;
          font-style: italic;
        }
    }

    .description {
      margin-bottom: 50px;

      p {
        font-size: 20px;
      }
    }

    .persons {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      flex-wrap: wrap;

    
    }
  }
  .dontshow {
    display: none;
  }
}

@media (max-width:$EXTRASMALLSCREEN) {
  .team-page {
    .dzial-container {
      .header-container {
        h1 {
          font-size: 50px;
        }
      }
    }
  }
}

@media (max-width:$EXTRAEXTRASMALLSCREEN) {
  .team-page {
    .dzial-container {
      .header-container {
        h1 {
          font-size: 35px;
        }
      }
    }
  }
}