@import "./variables";

body,html {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Maven Pro', sans-serif;
  
  }
  
  .App {
    overflow-x: hidden;
    box-sizing: border-box;
    background-color: #FFFFFA;
  }
  

.main-image {
    position: relative;
    height: 60vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;


    &::before {    
      content: "";
      background-size: cover;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 0.3;
    }

    h1 {
      position: relative;
      color: #ffffff;  
      font-size: 120px;
      line-height: 0.9;
      text-align: center;
      // text-shadow: black;
      // text-shadow: 0 0 3px rgba(255,255,255,.5), 0 0 10px rgba(255,255,255,.5), 0px 0px 4px rgba(0,0,0,0.57);
    }
  }

