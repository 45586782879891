body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Maven Pro", sans-serif;
}

.App {
  overflow-x: hidden;
  box-sizing: border-box;
  background-color: #FFFFFA /*#262B30*/;
}

.main-image {
  position: relative;
  height: 70vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-image::before {
  content: "";
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.3;
}
.main-image h1 {
  position: relative;
  color: #ffffff;
  font-size: 120px;
  line-height: 0.9;
  text-align: center;
}/*# sourceMappingURL=App.css.map */

@media (max-width: 620px) {
  .main-image h1 {
	  font-size: 80px;
  }
  .main-image::before {
	  background-position: center;
  }
}