@import "../variables";

.about-page {
  // width: 60vw;
  width: 100%;

  // height: 100vh;
  background-color: $WHITECOLOR;
  padding-bottom: 50px;

  .main-image {

    &::before {    
      content: "";
      background-image: url('../../images/About/AboutImg.jpg');
      opacity: 0.3;
    }
  }

  .main-text-container {
    padding-top: 100px;
    // width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin:auto;

    .red-color {
      color: $REDCOLOR
    }

    .opening-text {
        // color: white;
        color: $FONTCOLORBLACK;
        width: 100%;

        h2 {
          font-size: 30px;
        }

        h2:nth-of-type(2) {
          padding-top: 50px;
          font-size: 35px;
        }

        ul {
          list-style: none;
          li {
            list-style-position: inside;
            font-size: 25px;
            font-weight: 200;

            &::before {
              content: "- ";
              color: $REDCOLOR;
            }
          }
        }
    }

    .img-container-about {
      margin-top: 100px;
      width: 90%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      img {
        margin: 10px;
        width: 70%;
      }
    }

    .history-text {
      margin-top: 100px;
      width: 100%;
      // background-color: black;
      // flex-direction: column;

      .history-text-container {
        // margin: auto;
        display: flex;
        h1 {
          position: relative;
          text-align: left;
          color:$FONTCOLORBLACK;
          font-size: 70px;
  
          // &::after {
          //   content: ""; 
          //   position: absolute;
          //   bottom: 0;
          //   left:0;
          //   height:5px;
          //   width: 100%;
          //   background-color: $REDCOLOR;
          // }
        }
      }

      h2 {
        padding-top:20px;
        color: $FONTCOLORBLACK;
        font-size: 25px;
        font-weight: 200;
      }

    }
  }
}