.admin-page .admin-nav {
  height: 50px;
  width: 100%;
  background-color: #BF4046;
}
.admin-page .main-panel {
  width: 70%;
  margin: auto;
  margin-top: 50px;
  border-radius: 10px;
  border: 3px solid gray;
}
.admin-page .main-panel .create-sponsor {
  float: right;
  transform: translateX(-30px);
}
.admin-page .main-panel button {
  font-size: 25px;
  border: 0px;
  padding: 0px;
  color: #BF4046;
  background-color: transparent;
  transition: color 0.2s;
}
.admin-page .main-panel button:hover {
  color: black;
  cursor: pointer;
}
.admin-page .main-panel .list-sponsors table {
  width: 100%;
}
.admin-page .main-panel .list-sponsors table tr {
  text-align: center;
}
.admin-page .form-create {
  margin: auto;
  width: 60%;
  display: flex;
  flex-direction: column;
}
.admin-page .form-create input[type=text] {
  height: 50px;
  font-size: 30px;
  outline: none;
  border: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0;
  width: 40%;
  border-bottom: 2px solid #BF4046;
  cursor: pointer;
}
.admin-page .form-create input[type=submit] {
  margin-top: 20px;
  background-color: #BF4046;
  font-size: 30px;
  width: 200px;
  height: 60px;
  color: white;
  outline: none;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
}
.admin-page .form-create input[type=file] {
  display: none;
}
.admin-page .form-create .custom-file-upload {
  width: 40%;
  border: 1px solid #ccc;
  display: inline-block;
  padding: 30px 12px;
  cursor: pointer;
}/*# sourceMappingURL=AdminPage.css.map */