.wut-button {
  all: unset;
  cursor: pointer;
  width: 200px;
  height: 60px;
  background-color: white;
  border-radius: 3px;
  color: #1E212B;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  transition: all 0.2s;
}
.wut-button:hover {
  font-size: 22px;
}
@media screen and (max-width: 620px) {
  .wut-button {
    width: 150px;
  }
}

.main-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #1E212B;
}
.main-page .front-page {
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
}
.main-page .front-page .main-video-div {
  text-align: center;
  z-index: 2;
}
.main-page .front-page .main-video-div .main-video > div {
  display: flex;
  background-size: cover;
  background-position: top;
  width: 100%;
  height: 100vh;
  /*-o-object-fit: cover;
     object-fit: cover;
  overflow-x: hidden;
  overflow-y: auto;*/
  filter: brightness(80%);
  animation-name: changebright;
  animation-duration: 0.2s;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
}
@keyframes changebright {
  from {
    filter: brightness(80%);
  }
  to {
    filter: brightness(50%);
  }
}
.main-page .front-page .front-page-elements {
  position: absolute;
  top: 50%;
  left: 0px;
  width: 100%;
  height: 50%;
  font-size: 50px;
  z-index: 10;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0%;
  animation-name: changeopacity;
  animation-duration: 0.6s;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
  text-align: center;
}
.main-page .front-page .front-page-elements h1 {
  font-size: 64px;
  opacity: 0%;
  animation-name: changeopacity;
  animation-duration: 1s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
}
.main-page .front-page .front-page-elements .line {
  margin-top: 5px;
  #margin-bottom: 20px;
  height: 3px;
  width: 0px;
  background-color: #e31922;
  animation-name: changewidth;
  animation-duration: 1s;
  animation-delay: 3s;
  animation-fill-mode: forwards;
}
@keyframes changewidth {
  from {
    width: 0px;
  }
  to {
    width: 250px;
  }
}
.main-page .front-page .front-page-elements h2 {
  opacity: 0%;
  font-size: 32px;
  animation-name: changeopacity;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
.main-page .front-page .front-page-elements h2:nth-of-type(1) {
  animation-delay: 2.5s;
}
.main-page .front-page .front-page-elements h2:nth-of-type(2) {
  animation-delay: 3s;
}
.main-page .front-page .front-page-elements #motorsport-text {
  font-size: 40px;
  font-style: italic;
}
@keyframes changeopacity {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}
.main-page .front-page .animated-lines {
  position: absolute;
  bottom: -300px;
  right: 60px;
  display: flex;
  position: row;
}
.main-page .front-page .animated-lines div {
  background-color: #e31922;
  width: 6px;
  height: 700px;
  margin: 5px;
  rotate: 30deg;
  transform: translate(250px, -144px);
  animation-name: changeheight;
  animation-duration: 1.5s;
  animation-delay: 2.5s;
  animation-fill-mode: forwards;
}
@keyframes changeheight {
  from {
    transform: translate(250px, -144px);
  }
  to {
    transform: translate(0px, 0px);
  }
}
.main-page .about-front-page {
  width: 1100px;
  max-width: 90%;
  margin: auto;
  z-index: 20;
  padding-bottom: 50px;
}
.main-page .about-front-page .about-headline {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-page .about-front-page .about-headline h1 {
  position: relative;
  text-align: right;
  margin-top: 70px;
  font-size: 90px;
  font-weight: 600;
  text-align: center;
}

@media screen and (max-width: 620px){
	.main-page .about-front-page .about-headline h1 {
		font-size: 50px;
	}
}

.main-page .about-front-page .about-headline h1::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 5px;
  bottom: 0;
  left: 0;
  background-color: #e31922;
}
.main-page .about-front-page .info-kim {
  display: flex;
  flex-direction: column;
   text-align: justify;
}
.main-page .about-front-page .info-kim .kim-jestesmy-paragraph {
  margin-top: 50px;
  font-size: 24px;
}

@media screen and (max-width: 1400px){
  .main-page .about-front-page .info-kim .kim-jestesmy-paragraph {

  font-size: 22px;
}
}
@media screen and (max-width: 620px){
  .main-page .about-front-page .info-kim .kim-jestesmy-paragraph {

  font-size: 18px;
}
}
.main-page .about-front-page .info-kim .stats-div {
  width: 660px;
  max-width: 100%;
  margin: auto;
  margin-top: 70px;
  text-align: center;
}
.main-page .about-front-page .info-kim .stats-div h2 {
  font-size: 32px;
}
.main-page .about-front-page .info-kim .stats-div .stats {
  width: 90%;
  margin: auto;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-page .about-front-page .info-kim .stats-div .stats h3:nth-of-type(1) {
  font-size: 40px;
  display: block;
}
.main-page .about-front-page .info-kim .stats-div .stats h3:nth-of-type(2) {
  font-size: 20px;
  display: block;
}
.main-page .about-front-page .info-kim .stats-div .stats svg {
  height: 100px;
  width: 100px;
  max-width: 80%;
  margin: 0px 3px;
}/*# sourceMappingURL=MainPage.css.map */

.main-page .about-front-page .info-kim .stats-div .stats .ludzie svg {
	height: 85px;
	margin-bottom: 15px;
}