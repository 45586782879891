@import "../variables";

.admin-page {
    .admin-nav {
        height: 50px;
        width: 100%;
        background-color: $REDSHADOWCOLOR;
    }
    
    .main-panel {
        width: 70%;
        margin: auto;
        margin-top: 50px;
        border-radius: 10px;
        border: 3px solid gray;

        .create-sponsor {
            // text-align: right;
            float: right;
            transform: translateX(-30px);
        }

        button {
            font-size: 25px;
            border: 0px;
            padding: 0px;
            color: $REDSHADOWCOLOR;
            background-color: transparent;
            transition: color 0.2s;

            &:hover {
                color: black;
                cursor: pointer;
            }
        }
        .list-sponsors {
            table {
                width: 100%;

                tr {
                    text-align: center;
                }
                // .sponsor {
                //     display: flex;
                //     flex-direction: row;
                //     justify-content: space-between;
                //     // border: 2px solid black;
                //     border-bottom: 2px solid black;
                //     height: 50px;
                //     margin: 5px;
        
                //     h1 {
                //         font-size: 20px;
                //         margin: auto 0px auto 0px;
                //     }
                //     img {
                //         height: 300px;
                //         width: 300px;
        
                        
                //     }
                // }
            }

        }
    }

    //create
    .form-create {
        margin: auto;
        width: 60%;

        display: flex;
        flex-direction: column;

        input[type=text] {
            height: 50px;
            font-size: 30px;
            outline: none;
            border: 0;
            margin-top:10px;
            margin-bottom:10px;
            padding: 0;
            width: 40%;
            //background-color: gray;
            border-bottom: 2px solid $REDSHADOWCOLOR;
            cursor:pointer;
        }

        input[type=submit] {
            margin-top: 20px;
            background-color: $REDSHADOWCOLOR;
            font-size: 30px;
            width: 200px;
            height: 60px;
            color: white;
            outline: none;
            border: 0;
            border-radius: 5px;
            cursor:pointer;
        }

        input[type=file] {
            display: none;
        }

        .custom-file-upload {
            width: 40%;
            border: 1px solid #ccc;
            display: inline-block;
            padding: 30px 12px;
            cursor: pointer;
        }
    }
}