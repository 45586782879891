@import "./variables";

.footer {
    color:$WHITECOLOR;
  
    height: 400px;
    width: 100%;
    background-color: black;
    margin-top: 100px;
  
    .container {
      width: 90%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 30px;
      margin-left:auto;
      margin-right:auto;
    
      .img-logo, .contact-media, .contact-address {
        flex: 1;
        height: 100%;
  
        h1 {
          text-align: center;
        }
      }
    
      .contact-media, .contact-address {
        padding-top: 80px;
        font-size: 18px;
  
        svg {
          // display: inline-block;
          vertical-align: middle;
          transform: scale(2);
        }
        }
  
      .img-logo {
        
        display: flex;
        align-items: center;
        img {
          width: 100%;
          // margin-top: auto;
        }
      }
  
      .contact-media {
  
        .icons-media {
          height: 50%;
          display: flex;
          justify-content: space-around;
          align-items: center;
          flex-direction: row;
  
          a {
            all: unset;
            
            cursor: pointer;
          }
        }
      }
  
      .contact-address {
        // padding-top: 10px;
        .info {
          opacity: 0.8;
          font-size: 13px;
          font-weight: 200;
          padding-top: 10px;
  
          .addresss {
  
            .first-element {
              display: flex;
              flex-direction: row;
              align-items: center;
  
              h2 {
                margin-left: 10px;
              }
            }
          
          }
        }
      }
  
      .mail-container, .marketing-container {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
  
        h2 {
          margin-left: 10px;
        }
      }
    }
  }
  

  @media (max-width: $SMALLNORMALSCREEN) {
    .footer {
      .container {
        .contact-media, .contact-address {
          font-size: 16px;
        }

        .contact-address {
          .info {
            font-size: 11px;
          }
        }
      }
    }
  }


  @media (max-width: $SMALLSCREEN) {
    .footer {
      .container {
        flex-direction: column;
        .contact-media, .contact-address {
          font-size: 13px;
        }

        .contact-address {
          .info {
            font-size: 10px;
          }
        }

        .contact-media {
          .icons-media {
            width: 30%;
          }
        }
      }
    }
  }