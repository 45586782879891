@import "../variables";


.bolidy-front-page {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  width: 65%;


  background-color: $WHITECOLOR;
  padding-top: 50px;
  padding-bottom: 50px;
  color: $FONTCOLORBLACK;


  .bolidy-header-appear, .bolidy-header {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    

    h1 {
      position: relative;
      text-align: center;
      font-size: 80px;

      &::after {
        content: ""; 
        position: absolute;
        bottom:0;
        left:0;
        transition: all 1s;
  
        height: 100%;
        width: 100%;
        background-color: $FONTCOLORBLACK;
      }
    }
  }

  .not-main {
    width:100vw;
    background-color: $REDSHADOWCOLOR ;
    margin-top: 50px;
  }

  // .not-main {
  //   background-color: gray;
  // }

  .bolidy-header-appear {
    h1 {
      &::after {
        height: 5px;
      }
    }
  }

  .bolid-info {
    padding-top: 50px;

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 700px;

    .bolid-image {
      position: relative;
      flex: 3;
      z-index: 5;


      img {
        position: relative;
        z-index: 10;
        width: 100%;
      }

      .red-lines {
        position: absolute;
        z-index: 1;
        top: -10%;
        left: 30%;
        width: 300px;
        height: 0%;
        
        display: flex;
        flex-direction: row;

        transition: 1.2s all;

        div {
          margin-left: 30px;
          width: 45px;
          height: 100%;
          background-color: $REDCOLOR;
          transform: skew(20deg);
          
        }
      }
    }

    .straight-line {
      height: 100%;
      width: 5px;
      background-color: $REDCOLOR;
      margin-left: 40px;
      margin-right: 40px;

    }

    .bolid-stats {
      height: 100%;
      flex: 2;
      display: flex;
      flex-direction: column;
      

      div {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        // margin-top: auto;
        // margin-bottom: auto;

        svg {
          height: 100px;
          width: 100px;
        }

        .text-container {
          margin: auto;
          display: flex;
          flex-direction: column;
          opacity: 0;
          transition: all 1s;

          h1 {
            font-size: 35px;
            text-align: center;
          }
          h2 {
            font-size: 20px;
            color: $REDSHADOWCOLOR;
            opacity: 0.7;
            text-align: center;
          }
        }
      }

    }
  }

  .bolid-button {
    margin: auto;
    margin-top: 50px;
    width: 250px;
    background-color: $REDSHADOWCOLOR;
    color: white;
  }

}

@media screen and (max-width: $EXTRALARGESCREEN) {
  .bolidy-front-page  { 
    width: 75%;
    .bolid-info {
      .bolid-image {
        flex: 4;
      }
    }
  }
}

@media screen and (max-width: $NORMALSCREEN) {
  .bolidy-front-page {
    width: 80%;

    .bolid-info .bolid-stats div {
      svg {
        width: 85px;
        height: 85px;
      }

      .text-container {
        h1 {
          font-size: 25px;
        }
  
        h2 {
          font-size: 20px;
        }
      }

    }
  }
}

@media screen and (max-width: $SMALLNORMALSCREEN) {
  .bolidy-front-page {
    width: 85%;

    .bolidy-header-appear, .bolidy-header {
      h1 {
        font-size: 65px;
      }
    }
    .bolid-info {
      height: 550px;

      .bolid-image {
        .red-lines {
          left: 20%;

          div {
            width: 35px;
          }
        }
      }

      .bolid-stats div {
        svg {
          width: 85px;
          height: 85px;
        }
  
        .text-container {
          h1 {
            font-size: 25px;
          }
    
          h2 {
            font-size: 20px;
          }
        }
  
      }
    }

  }
}

@media screen and (max-width: $SMALLSCREEN) {
  .bolidy-front-page { 
    width: 90%;

    .bolidy-header-appear, .bolidy-header {
      h1 {
        font-size: 55px;
      }
    }

    .bolid-info {
      justify-content:space-between;

      .bolid-image {
        .red-lines {
          left: 20%;

          div {
            width: 30px;
          }
        }
      }
      .bolid-stats div {
        svg {
          width: 75px;
          height: 75px;
        }
  
        .text-container {
          h1 {
            font-size: 20px;
          }
    
          h2 {
            font-size: 18px;
          }
        }
  
      }
    }
  }
}

@media screen and (max-width: $EXTRASMALLSCREEN) {
  .bolidy-front-page { 
    .bolidy-header-appear, .bolidy-header {
      h1 {
        font-size: 45px;
      }
    }


    .bolid-info {
      flex-direction: column;

      // height: 750px;

      .bolid-image {
        
        img {
          height: 100%;
          width: 100%;
        }

        margin-bottom: 100px;
      }

      .straight-line {
        display: none;
      }

      .bolid-stats {
        // max-height: 200px;
        flex-direction: row;

        div {
          flex-direction: column;


          svg {
            width: 90px;
            height: 90px;
          }
    
          .text-container {
            h1 {
              font-size: 20px;
            }
      
            h2 {
              font-size: 18px;
            }
          }
    
        }
      }
    }

    
  }

  .bolid-button {
    transform: translateY(150px);
  }
}

@media screen and (max-width: $EXTRAEXTRASMALLSCREEN) {
  .bolidy-front-page { 
    .bolid-info {
      .bolid-image {
        .red-lines {
          // display: none;
          left: 10%;

          div {
            width: 25px;
          }
        }
      }

      .bolid-stats {
        flex: 1;
        div {
          svg {
            width: 60px;
            height: 60px;
          }

          .text-container {
            h1 {
              font-size: 15px;
            }
      
            h2 {
              font-size: 15px;
            }
          }
        }
      }
     }
  }

  .bolid-button {
    transform: translateY(0px);
  }
}