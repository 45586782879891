@import "../variables";

.partners-page {
    width: 100%;
    margin-top: 200px;

    .headline-partners {
        position: relative;
        font-size: 64px;
        color: $FONTCOLORBLACK;
        margin-bottom: 40px;
        margin-top: 40px;
        text-align: center;

        &::after {
            content: ""; 
            position: absolute;
            bottom:0;
            left:0;
            transition: all 1s;
      
            height: 5px;
            width: 100%;
            background-color: $FONTCOLORBLACK;
          }
    }

    .strategiczni {
        margin: auto;
        width: 80%;
        a {
            flex-basis: 30%;
        }
    }

    .glowni {
        a {
            flex-basis: calc(20% - 20px);
        }
    }

    .zaufali {
        a {
            flex-basis: calc(16% - 20px);
        }
    }
}

// .partners-main-page {
//     width: 100%;
// }

.partners-container {
    width: 70%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}