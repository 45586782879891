.bolid-page {
  width: 100vw;
}
.bolid-page .main-image::before {
  background-image: url("../../images/Bolid/BolidMain.jpg");
  opacity: 0.9;
}
.bolid-page .bolids-animation {
  margin-top: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.bolid-page .bolids-animation .circle {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #e31922;
}
.bolid-page .bolids-animation .firstLine {
  overflow-y: hidden;
  position: relative;
  width: 10px;
  height: 300px;
}
.bolid-page .bolids-animation .firstLine .main-line {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 0px;
  background: #e31922;
  z-index: 5;
}
.bolid-page .bolids-animation .firstLine .gray-background {
  position: absolute;
  top: 0px;
  left: 2px;
  width: 6px;
  height: 300px;
  background: gray;
  z-index: 2;
}
.bolid-page .bolids-animation .WUT3-bolid {
  height: 60vh;
  width: 80vw;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
}
.bolid-page .bolids-animation .WUT3-bolid .bolid-name {
  text-align: center;
  color: white;
  font-size: 50px;
  color: black;
}
.bolid-page .bolids-animation .WUT3-bolid .container {
  display: flex;
  flex-direction: row;
}
.bolid-page .bolids-animation .WUT3-bolid .container .image-bolid {
  -o-object-fit: contain;
     object-fit: contain;
  height: 80%;
  width: 100%;
  opacity: 1;
}/*# sourceMappingURL=BolidPage.css.map */