.bolidy-front-page {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 65%;
  background-color: #FFFFFA;
  /*padding-top: 50px;*/
  padding-bottom: 50px;
  color: #1E212B;
}
.bolidy-front-page .bolidy-header-appear h1, .bolidy-front-page .bolidy-header h1 {
  position: relative;
  text-align: center;
  font-size: 80px;
}
.bolidy-front-page .bolidy-header-appear h1::after, .bolidy-front-page .bolidy-header h1::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 1s;
  height: 100%;
  width: 100%;
  background-color: #1E212B;
}
.bolidy-front-page .not-main {
  width: 100vw;
  background-color: /*#BF4046*/#e31922;
  #margin-top: 50px;
}
.bolidy-front-page .bolidy-header-appear h1::after {
  height: 5px;
}
.bolidy-front-page .bolidy-header-appear h1::before {
  height: 5px;
}
.bolidy-front-page .bolid-info {
  padding-top: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 700px;
}
.bolidy-front-page .bolid-info .bolid-image {
  position: relative;
  flex: 3;
  z-index: 5;
}
.bolidy-front-page .bolid-info .bolid-image img {
  position: relative;
  z-index: 10;
  width: 100%;
}
.bolidy-front-page .bolid-info .bolid-image .red-lines {
  position: absolute;
  z-index: 1;
  top: -10%;
  left: 30%;
  width: 300px;
  height: 120%;
  display: flex;
  flex-direction: row;
  transition: 1.2s all;
}
.bolidy-front-page .bolid-info .bolid-image .red-lines div {
  margin-left: 30px;
  width: 45px;
  height: 100%;
  background-color: #e31922;
  transform: skew(20deg);
}
.bolidy-front-page .bolid-info .straight-line {
  height: 100%;
  width: 5px;
  background-color: #e31922;
  margin-left: 40px;
  margin-right: 40px;
}
.bolidy-front-page .bolid-info .bolid-stats {
  height: 100%;
  flex: 2;
  display: flex;
  flex-direction: column;
}
.bolidy-front-page .bolid-info .bolid-stats div {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.bolidy-front-page .bolid-info .bolid-stats div svg {
  height: 100px;
  width: 100px;
}
.bolidy-front-page .bolid-info .bolid-stats div .text-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transition: all 1s;
}
.bolidy-front-page .bolid-info .bolid-stats div .text-container h1 {
  font-size: 35px;
  text-align: center;
}
.bolidy-front-page .bolid-info .bolid-stats div .text-container h2 {
  font-size: 20px;
  color: #DF4046;
  opacity: 1;
  text-align: center;
}
.bolidy-front-page .bolid-button {
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 250px;
  background-color: #e31922;
  color: white;
}


@media screen and (max-width: 1434px) {
  .bolidy-front-page {
    width: 75%;
  }
  .bolidy-front-page .bolid-info .bolid-image {
    flex: 4;
  }
}
@media screen and (max-width: 1124px) {
  .bolidy-front-page {
    width: 80%;
  }
  .bolidy-front-page .bolid-info .bolid-stats div svg {
    width: 85px;
    height: 85px;
  }
  .bolidy-front-page .bolid-info .bolid-stats div .text-container h1 {
    font-size: 25px;
  }
  .bolidy-front-page .bolid-info .bolid-stats div .text-container h2 {
    font-size: 20px;
  }
}
@media screen and (max-width: 1024px) {
  .bolidy-front-page {
    width: 85%;
  }
  .bolidy-front-page .bolidy-header-appear h1, .bolidy-front-page .bolidy-header h1 {
    font-size: 65px;
  }
  .bolidy-front-page .bolid-info {
    min-height: 550px;
  }
  .bolidy-front-page .bolid-info .bolid-image .red-lines {
    left: 20%;
  }
  .bolidy-front-page .bolid-info .bolid-image .red-lines div {
    width: 35px;
  }
  .bolidy-front-page .bolid-info .bolid-stats div svg {
    width: 85px;
    height: 85px;
  }
  .bolidy-front-page .bolid-info .bolid-stats div .text-container h1 {
    font-size: 25px;
  }
  .bolidy-front-page .bolid-info .bolid-stats div .text-container h2 {
    font-size: 20px;
  }
}
@media screen and (max-width: 820px) {
  .bolidy-front-page {
    width: 90%;
  }
  .bolidy-front-page .bolidy-header-appear h1, .bolidy-front-page .bolidy-header h1 {
    font-size: 55px;
  }
  .bolidy-front-page .bolid-info {
    justify-content: space-between;
  }
  .bolidy-front-page .bolid-info .bolid-image .red-lines {
    left: 20%;
  }
  .bolidy-front-page .bolid-info .bolid-image .red-lines div {
    width: 30px;
  }
  .bolidy-front-page .bolid-info .bolid-stats {
	  margin-top: 50px;
  }
  .bolidy-front-page .bolid-info .bolid-stats div svg {
    width: 75px;
    height: 75px;
	margin-bottom: 20px;
	margin-top: 20px;
  }
  .bolidy-front-page .bolid-info .bolid-stats div .text-container h1 {
    font-size: 20px;
  }
  .bolidy-front-page .bolid-info .bolid-stats div .text-container h2 {
    font-size: 18px;
  }
}
@media screen and (max-width: 620px) {
  .bolidy-front-page .bolidy-header-appear h1, .bolidy-front-page .bolidy-header h1 {
    font-size: 45px;
  }
  .bolidy-front-page .bolid-info {
    flex-direction: column;
	height: auto;
	min-height: 0px;
  }
  .bolidy-front-page .bolid-info .bolid-image {
    #margin-bottom: 100px;
  }
  .bolidy-front-page .bolid-info .bolid-image img {
    #height: 100%;
    width: 100%;
  }
  .bolidy-front-page .bolid-info .bolid-image .red-lines{
	  #height: 90%;
  }
  .bolidy-front-page .bolid-info .straight-line {
    display: none;
  }
  .bolidy-front-page .bolid-info .bolid-stats {
    flex-direction: row;
  }
  .bolidy-front-page .bolid-info .bolid-stats div {
    flex-direction: column;
  }
  .bolidy-front-page .bolid-info .bolid-stats div svg {
    #width: 90px;
    #height: 90px;
	width: 100%;
  }
  .bolidy-front-page .bolid-info .bolid-stats div .text-container h1 {
    font-size: 20px;
  }
  .bolidy-front-page .bolid-info .bolid-stats div .text-container h2 {
    font-size: 18px;
  }
  .bolidy-front-page .bolid-button {
    /*transform: translateY(100px);
	margin-top: 100px;*/
  }
}
@media screen and (max-width: 440px) {
  .bolidy-front-page .bolid-info .bolid-image .red-lines {
    left: 10%;
  }
  .bolidy-front-page .bolid-info .bolid-image .red-lines div {
    width: 25px;
  }
  .bolidy-front-page .bolid-info .bolid-stats {
    flex: 1;
  }
  .bolidy-front-page .bolid-info .bolid-stats div svg {
    width: 60px;
    height: 60px;
  }
  .bolidy-front-page .bolid-info .bolid-stats div .text-container h1 {
    font-size: 15px;
  }
  .bolidy-front-page .bolid-info .bolid-stats div .text-container h2 {
    font-size: 15px;
  }
  .bolid-button {
    transform: translateY(0px);
  }
}/*# sourceMappingURL=BolidyPage.css.map */