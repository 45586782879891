@import "../variables";

.wut-button {
  all: unset;
  cursor: pointer;
  width: 200px;
  height: 60px;
  // background-color: $REDCOLOR;
  background-color: white;
  border-radius: 3px;
  color: $FONTCOLORBLACK;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  transition: all 0.2s;

  &:hover {
    font-size: 22px;
  }

  @media screen and (max-width: $EXTRASMALLSCREEN) {
    width: 150px;
  }
}


.main-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: $FONTCOLORBLACK;
  // color: blue;
  

  .front-page {

    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px;

    .main-video-div {
      text-align: center;
      z-index: 2;
    
      .main-video {
        width:100%;
        height: 100vh;
        object-fit: cover;

        overflow-x: hidden;
        overflow-y: auto;
        
        filter: brightness(80%);
    
        animation-name: changebright;
        animation-duration: 0.2s;
        animation-delay: $timeshow;
        animation-fill-mode: forwards;
      }
    
    
      @keyframes changebright {
        from {filter: brightness(80%);}
        to {filter: brightness(20%);}
      }
    }


    .front-page-elements {
      position: absolute;
      top: 50%;
      left: 0px;
      width: 100%;
      height: 50%;

      font-size: 50px;
      z-index: 10;
      color: white;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      opacity: 0%;

      animation-name: changeopacity;
      animation-duration: 0.6s;
      animation-delay: $timeshow;
      animation-fill-mode: forwards;

      h1 {
        font-size: 64px;
        opacity: 0%;
        animation-name: changeopacity;
        animation-duration: 1s;
        animation-delay: $timeshow;
        animation-fill-mode: forwards
      }

      .line {
        margin-top: 5px;
        margin-bottom: 20px;
        height: 3px;
        width: 0px;
        
        background-color: $REDCOLOR;
        animation-name: changewidth;
        animation-duration: 1s;
        animation-delay: 5s;
        animation-fill-mode: forwards;

        @keyframes changewidth {
          from {width: 0px;}
          to {width: 250px;}
        }
      }

      h2 {
        opacity: 0%;
        font-size: 32px;
        animation-name: changeopacity;
        animation-duration: 1s;
        animation-fill-mode: forwards;

        &:nth-of-type(1) {
          animation-delay: 4.5s;
        }
  
        &:nth-of-type(2) {
          animation-delay: 5s;
        }
      }

      #motorsport-text {
        font-size: 40px;
        font-style: italic;
      }
    }

    @keyframes changeopacity {
      from {opacity: 0%;}
      to {opacity: 100%;}
    }

    .animated-lines {
      position: absolute;
      bottom: -300px;
      right: 60px;
      display: flex;
      position: row;
      

      div {
        background-color: $REDCOLOR;
        width: 6px;
        height: 700px;
        margin: 5px;

        rotate: 30deg;
        transform: translate(250px, -144px);
        animation-name: changeheight;
        animation-duration: 1.5s;
        animation-delay: $timeshow;
        animation-fill-mode: forwards;
      }

      @keyframes changeheight {
        from {transform: translate(250px, -144px)}
        to {transform: translate(0px, 0px)}
      }
    }
  }

  .about-front-page {
    width: 60%;
    margin: auto;
    z-index: 20;
    padding-bottom: 50px;

    .about-headline {
      display: flex;
      justify-content: center;
      align-items: center;
      
      h1 {
        position: relative;
        text-align: right;
        margin-top: 90px;
        // color: black;
        font-size: 90px;
        font-weight: 600;
        text-align: center;
  
        &::before {
          content: ""; 
          position: absolute;
          width: 100%;
          height: 5px;
          bottom: 0;
          left: 0;
          background-color: $REDCOLOR;
      }   
      }
    }

    .info-kim {
      display: flex;
      flex-direction: column;

      .kim-jestesmy-paragraph {
        margin-top: 50px;
        font-size: 25px;
      }

      .stats-div {
        width: 60%;
        margin: auto;
        
        margin-top: 70px;
        text-align: center;

        

        h2 {
          font-size: 32px;
        }

        .stats {
          width: 90%;
          margin: auto;
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          h3:nth-of-type(1) {
            font-size: 40px;
            display: block;
          }

          h3:nth-of-type(2) {
            font-size: 20px;
            display: block;
          }
          
          svg {
            height: 100px;
            width: 100px;
          }
        }

      }
    }
  }
}