.footer {
  color: #FFFFFA;
  min-height: 250px;
  width: 100%;
  background-color: black;
  margin-top: 50px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-top-style: solid;
  border-top-color: red;
  border-top-width: 5px;
}
.footer .container {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin: auto;
  margin-left: auto;
  margin-right: auto;
}
.footer .container .img-logo, .footer .container .contact-media, .footer .container .contact-address {
  flex: 1;
  height: 250px;
}
.footer .container .img-logo h1, .footer .container .contact-media h1, .footer .container .contact-address h1 {
  text-align: center;
}
.footer .container .img-logo h2, .footer .container .contact-media h2, .footer .container .contact-address h2 {
  font-size: 1.3em;
}
.footer .container .contact-media, .footer .container .contact-address {
  #padding-top: 50px;
  font-size: 18px;
}
.footer .container .contact-media {
	padding-top: 30px;
}
.footer .container .contact-media svg, .footer .container .contact-address svg {
  vertical-align: middle;
  transform: scale(2);
}
.footer .container .img-logo {
  display: flex;
  align-items: center;
}
.footer .container .img-logo img {
  height: 100%;
  margin: auto;
  #padding-top: 20px;
}
.footer .container .contact-media .icons-media {
  height: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}

.footer .container .contact-address h1:hover {
	#text-decoration-line: underline;
	cursor: pointer;
}

.footer .container .contact-media .icons-media a {
  all: unset;
  cursor: pointer;
}
.footer .container .contact-address .info {
  opacity: 0.8;
  font-size: 13px;
  font-weight: 200;
  padding-top: 10px;
  max-width: 395px;
  margin: auto;
}
.footer .container .contact-address .info .addresss .first-element {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.footer .container .contact-address .info .addresss .first-element h2 {
  margin-left: 10px;
}
.footer .container .mail-container, .footer .container .marketing-container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.footer .container .mail-container h2, .footer .container .marketing-container h2 {
  margin-left: 10px;
}

@media (max-width: 1024px) {
  .footer .container .contact-media, .footer .container .contact-address {
    font-size: 16px;
  }
  .footer .container .contact-address .info {
    font-size: 11px;
  }
  
}
@media (max-width: 820px) {
  .footer .container {
    flex-direction: column;
	gap: 0px;
  }
  .footer .container .contact-media, .footer .container .contact-address {
    font-size: 14px;
  }
  .footer .container .contact-address .info {
    font-size: 11px;
  }
  .footer .container .contact-media {
	  margin-bottom: 20px;
  }
  .footer .container .contact-media h1{
	  margin-bottom: 30px;
  }
  .footer .container .img-logo {
	  order: 3;
  }
  .footer .container .img-logo img {
	  width: 70%;
	  height: auto;
  }
}/*# sourceMappingURL=footer.css.map */