.table{
	width: 100%;
	border-collapse: collapse;
	border: 1px solid;
}
.table tr{
	border: 1px solid;
}
.table td, th{
	text-align: center;
	padding: 5px;
}

.table .true{
	font-weight: bold;
}
