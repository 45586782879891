.slider {
    width: 100%;
    /*position: relative;*/
}
 
.slider ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
 
.slider li {
    /*position: absolute;
    top: 0;
    left: 0;
    opacity: 0;*/
    transition: opacity 1s ease-in-out;
}
 
.slider img {
    /*height: 100%;*/
    width: 100%;
}
 
.slider li.active {
    opacity: 1;
}