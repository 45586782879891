$timeshow: 3.8s;
$REDCOLOR: #e31922;
$REDSHADOWCOLOR: #BF4046;
$WHITECOLOR: #FFFFFA;
$FONTCOLORBLACK: #1E212B;
$EXTRALARGESCREEN: 1434px;
$LARGESCREEN: 1268px;
$NORMALSCREEN: 1124px;
$SMALLNORMALSCREEN: 1024px;
$SMALLSCREEN: 820px;
$EXTRASMALLSCREEN: 620px;
$EXTRAEXTRASMALLSCREEN: 440px;