.manage-front-page {
  background-color: #DF4046;
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: row;
}
.manage-front-page .manage-container {
  display: flex;
  margin: auto;
  width: 90%;
}
.manage-front-page .manage-container .manage-pictures {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.manage-front-page .manage-container .manage-pictures .holder-bent {
  #width: 267px;
  height: 400px;
  position: relative;
  transition: opacity 1s;
  opacity: 0%;
}
.manage-front-page .manage-container .manage-pictures .holder-bent img {
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.manage-front-page .manage-container .manage-pictures .holder-bent::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-top: 400px solid transparent;
  border-left: 60px solid #DF4046;
}
.manage-front-page .manage-container .manage-pictures .holder-bent::after {
  content: "";
  position: absolute;
  transition: unset;
  top: 0;
  right: 0px;
  width: 0;
  height: 0;
  border-top: 400px solid #DF4046;
  border-left: 60px solid transparent;
}
.manage-front-page .manage-container .manage-text {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-self: center;
  color: white;
}
.manage-front-page .manage-container .manage-text .text-headers {
  padding: 10px;
  border: 8px solid white;
}
.manage-front-page .manage-container .manage-text .text-headers h1 {
  font-size: 70px;
  font-weight: 600;
  text-align: center;
}
.manage-front-page .manage-container .manage-text button {
  margin-top: 100px;
}
/*
@media screen and (max-width: 1434px) {
  .manage-front-page .manage-container .manage-pictures .holder-bent {
    width: 240px;
    height: 360px;
  }
  .manage-front-page .manage-container .manage-text {
    flex: 1;
  }
  .manage-front-page .manage-container .manage-text .text-headers h1 {
    font-size: 60px;
  }
}
@media screen and (max-width: 1268px) {
  .manage-front-page {
    height: 500px;
  }
  .manage-front-page .manage-container .manage-pictures .holder-bent {
    width: 213px;
    height: 320px;
  }
  .manage-front-page .manage-container .manage-text {
    flex: 1;
  }
  .manage-front-page .manage-container .manage-text .text-headers h1 {
    font-size: 50px;
  }
}
@media screen and (max-width: 1268px) {
  .manage-front-page {
    height: 900px;
  }
  .manage-front-page .manage-container {
    flex-direction: column;
  }
  .manage-front-page .manage-container .manage-pictures {
    margin-top: 30px;
    margin: auto;
  }
  .manage-front-page .manage-container .manage-pictures .holder-bent {
    width: 280px;
    height: 420px;
  }
  .manage-front-page .manage-container .manage-pictures .holder-bent::before {
    border-top: 420px solid transparent;
    border-left: 70px solid #DF4046;
  }
  .manage-front-page .manage-container .manage-pictures .holder-bent::after {
    border-top: 420px solid #DF4046;
    border-left: 70px solid transparent;
  }
  .manage-front-page .manage-container .manage-text {
    margin-top: 20px;
    flex: 1;
  }
  .manage-front-page .manage-container .manage-text .text-headers h1 {
    font-size: 70px;
  }
  .manage-front-page .manage-container .manage-text button {
    margin-top: 50px;
  }
}*/

@media screen and (max-width: 1434px) {
	.manage-front-page {
		height: 500px;
	}
	.manage-front-page .manage-container .manage-pictures .holder-bent {
		height: 360px;
	}
	.manage-front-page .manage-container .manage-text .text-headers h1 {
		font-size: 60px;
	}
	.manage-front-page .manage-container .manage-text button {
		margin-top: 70px;
	}
	
}
@media screen and (max-width: 1024px) {
  .manage-front-page {
    height: 700px;
  }
  .manage-front-page .manage-container {
    flex-direction: column;
  }
  .manage-front-page .manage-container .manage-pictures {
    margin-top: 30px;
    margin: auto;
  }
  .manage-front-page .manage-container .manage-pictures .holder-bent {
    width: 220px;
    height: 330px;
  }
  .manage-front-page .manage-container .manage-pictures .holder-bent::before {
    border-top: 330px solid transparent;
    border-left: 60px solid #DF4046;
  }
  .manage-front-page .manage-container .manage-pictures .holder-bent::after {
    border-top: 330px solid #DF4046;
    border-left: 60px solid transparent;
  }
  .manage-front-page .manage-container .manage-text {
    margin-top: 20px;
	flex: 1;
  }
  .manage-front-page .manage-container .manage-text .text-headers h1 {
    font-size: 50px;
  }
}
@media screen and (max-width: 820px) {
  .manage-front-page {
    height: 600px;
  }
  .manage-front-page .manage-container .manage-pictures .holder-bent {
    width: 160px;
    height: 240px;
  }
  .manage-front-page .manage-container .manage-pictures .holder-bent::before {
    border-top: 240px solid transparent;
    border-left: 40px solid #DF4046;
  }
  .manage-front-page .manage-container .manage-pictures .holder-bent::after {
    border-top: 240px solid #DF4046;
    border-left: 40px solid transparent;
  }
  .manage-front-page .manage-container .manage-text .text-headers h1 {
    font-size: 40px;
  }
}
@media screen and (max-width: 620px) {
  .manage-front-page {
    height: 500px;
  }
  .manage-front-page .manage-container .manage-pictures .holder-bent {
    width: 140px;
    height: 210px;
  }
  .manage-front-page .manage-container .manage-pictures .holder-bent::before {
    border-top: 210px solid transparent;
    border-left: 30px solid #DF4046;
  }
  .manage-front-page .manage-container .manage-pictures .holder-bent::after {
    border-top: 210px solid #DF4046;
    border-left: 30px solid transparent;
  }
  .manage-front-page .manage-container .manage-text .text-headers h1 {
    font-size: 35px;
  }
  .manage-front-page .manage-container .manage-text button {
    margin-top: 30px;
  }
}
@media screen and (max-width: 440px) {
  .manage-front-page {
    height: 500px;
  }
  .manage-front-page .manage-container .manage-pictures .holder-bent {
    width: 120px;
    height: 180px;
  }
  .manage-front-page .manage-container .manage-pictures .holder-bent::before {
    border-top: 180px solid transparent;
    border-left: 30px solid #DF4046;
  }
  .manage-front-page .manage-container .manage-pictures .holder-bent::after {
    border-top: 180px solid #DF4046;
    border-left: 30px solid transparent;
  }
}/*# sourceMappingURL=Manage.css.map */