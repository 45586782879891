.navbar {
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 23px;
  z-index: 30;
  background-color: #000;
  transition: all 0.3s ease;
  height: 120px;
  transform: translateY(-200px);
  animation-name: movenavbar;
  animation-duration: 1.5s;
  animation-delay: 2.5s;
  animation-fill-mode: forwards;
}
@keyframes movenavbar {
  from {
    transform: translateY(-200px);
  }
  to {
    transform: translateY(0px);
  }
}
.navbar .navbar-logo {
  #transform: translateY(12px);
  margin: auto 0px;
}
.navbar .navbar-logo:hover {
  cursor: pointer;
}
.navbar .navbar-logo img {
  width: 400px;
}
.navbar .navbar-subsites {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  margin-bottom: auto;
}
.navbar .navbar-subsites .subsite-text-container {
  margin: auto 2px auto 2px;
  padding: 10px 40px 10px 40px;
  position: relative;
  display: flex;
  flex-direction: row;
  color: #fff;
  transition: all 0.2s;
}
.navbar .navbar-subsites .subsite-text-container::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #e31922;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.2s ease-in-out 0s;
}
.navbar .navbar-subsites .subsite-text-container:hover {
  cursor: pointer;
  color: #e31922;
}
.navbar .navbar-subsites .subsite-text-container:hover::before {
  visibility: visible;
  transform: scaleX(1);
}
.navbar .navbar-subsites .subsite-text-container .subsites-text {
  font-size: 1.2em;
  font-weight: 600;
  margin: auto;
  text-align: center;
}
.navbar .navbar-subsites .subsite-text-container h3 {
  color: rgba(255, 255, 255, 0.76);
  font-size: 0.94em;
  font-weight: 200;
  margin: auto 40px auto 40px;
  text-align: center;
  opacity: 0%;
  transition: all 0.5s ease;
  cursor: default;
}
.navbar .navbar-subsites .subsite-text-container .text-hovered {
  opacity: 100%;
}
.navbar .navbar-subsites .clicked::before {
  visibility: visible;
  transform: scaleX(1);
}
.navbar .navbar-subsites .countries {
  display: flex;
  flex-direction: column;
}
.navbar .navbar-subsites .countries:hover {
  cursor: unset;
}
.navbar .navbar-subsites .countries:hover::before {
  visibility: hidden;
}
.navbar .navbar-subsites .countries img {
  position: relative;
  margin: 5px;
}
.navbar .navbar-subsites .countries img:hover {
  cursor: pointer;
}
.navbar .navbar-subsites .countries picture.chosen-language {
  position: relative;
}
.navbar .navbar-subsites .countries picture.chosen-language::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: green;
}
.navbar .navbar-subsites .countries .chosen-language {
  position: relative;
}


@media screen and (max-width: 1434px) {
  .navbar .navbar-subsites .subsite-text-container {
    padding: 10px 25px 10px 25px;
  }
  .navbar {
	  height: 100px;
  }
}
@media screen and (max-width: 1268px) {
  .navbar .navbar-logo:hover {
    cursor: pointer;
  }
  .navbar .navbar-logo img {
    #width: 350px;
    #transform: translateY(4px);
  }
  .navbar .navbar-subsites .subsite-text-container {
    padding: 10px 13px 10px 13px;
  }
  .navbar .navbar-subsites .subsite-text-container .subsites-text {
    font-size: 1.1em;
    font-weight: 600;
    margin: auto;
    text-align: center;
  }
}
@media only screen and (max-width: 1024px) {
  .navbar .navbar-subsites {
    display: flex;
    flex-direction: row;
  }
  .navbar .navbar-subsites .subsite-text-container {
    margin: auto 0px auto 0px;
  }
}
.page-navbar {
  position: fixed;
  top: 0px;
  width: 100%;
  background-color: black;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.page-navbar .page-navbar-holder {
  width: 90%;
  margin: 0px auto 0px auto;
  display: flex;
  /*flex-direction: column;*/
  justify-content: center;
  z-index: 700;
}
.page-navbar .navbar-logo {
  cursor: pointer;
  display: flex;
  align-items: center;
  /*justify-content: center;*/
  margin-left: 0px;
  margin-right: 5px;
  transition: margin-left 0.5s ease-in;
}
.page-navbar .navbar-logo img {
  max-width: min(600px, 100%);
  /*transform: translateX(20px);*/
}
.page-navbar .navbar-logo .logo-text {
  font-size: 50px;
  margin: 0px;
  padding: 0px;
}

.navbar-menu {
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  /*justify-content: center;*/
}

.falling-nav {
  top: 100px;
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 0px;
  background-color: black;
  z-index: 800;
  transition: height 0.5s ease-in;
}
.falling-nav ul {
  list-style: none;
  margin: 0px auto 0px auto;
  width: 90%;
  list-style: none;
}
.falling-nav li {
  cursor: pointer;
  font-size: 40px;
  text-align: center;
  background-color: black;
  transition: background-color 0.2s ease-in;
}
.falling-nav li a {
  text-decoration: none;
  color: white;
}
.falling-nav li:hover {
  background-color: rgba(227, 25, 34, 0.6);
}
.falling-nav .border-bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.8);
}

.change .falling-nav {
  height: 250px;
}

.bar-container {
  display: inline-block;
  cursor: pointer;
}
.bar-container .bar1,
.bar-container .bar2,
.bar-container .bar3 {
  width: 35px;
  height: 5px;
  background-color: white;
  margin: 6px 0px;
  transition: 0.4s;
  border-radius: 10px;
}

.change .bar1 {
  transform: rotate(-45deg) translate(-7px, 6px);
}
.change .bar2 {
  opacity: 0;
}
.change .bar3 {
  transform: rotate(45deg) translate(-9px, -8px);
}

.unscrolled .navbar-menu-md,
.scrolled .navbar-menu-md {
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
}
.unscrolled .navbar-menu-md li,
.scrolled .navbar-menu-md li {
  height: 100%;
  font-size: 30px;
  list-style: none;
  margin-left: 40px;
  padding: 10px;
  transition: 0.2s;
}
.unscrolled .navbar-menu-md li:hover,
.scrolled .navbar-menu-md li:hover {
  cursor: pointer;
}/*# sourceMappingURL=Navbar.css.map */

.falling-nav .countries {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
}
.falling-nav .countries:hover {
  cursor: unset;
}
.falling-nav .countries:hover::before {
  visibility: hidden;
}
.falling-nav .countries img {
  position: relative;
  margin: 5px;
}
.falling-nav .countries img:hover {
  cursor: pointer;
}
.falling-nav .countries picture.chosen-language {
  position: relative;
}
.falling-nav .countries picture.chosen-language::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: green;
}
.falling-nav .countries .chosen-language {
  position: relative;
}